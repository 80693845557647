import React from 'react';
import reactHtmlParser from 'react-html-parser';
import Img from 'gatsby-image';
import { graphql, Link } from 'gatsby';
import { Layout } from '../components/index';

export default ({ data: { markdownRemark: { frontmatter: { image, title }, html } } }) => {
    return (
        <Layout pageTitle={title} showHeader activeTab={'about'}>
            <div id="wrapper">
                <section id="main" className="wrapper">
                    <div className="inner">
                        <h2 className="major">{title}</h2>
                        <Img className="image left" fixed={image.childImageSharp.fixed} alt={title} />
                        {reactHtmlParser(html)}
                        <Link to={"/"} className="button">Back to Home</Link>
                    </div>
                </section>
            </div>
        </Layout>
    );
}

export const articleQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
        image {
            childImageSharp {
                fixed(width: 180, height: 300) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
      }
    }
  }
`